import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue)

import CalendarView from "vue-simple-calendar"
import CalendarMathMixin from "vue-simple-calendar/dist/calendar-math-mixin.js"
import moment from "moment"
import _ from "lodash"
import axios from "axios"

require("vue-simple-calendar/dist/static/css/default.css")
require("vue-simple-calendar/dist/static/css/holidays-us.css")
/* eslint-disable no-new */
new Vue({
    el: '#home',
    data: function() {
        return {
            showDate: new Date(),
            currentMonth: null,
            nextMonth: null,
            previousMonth: null,
            events:[],
            currentEvent:null,
            calendarView:false,
            classificationFilter:'',
            creditTypeFilter:'',
            nameFilter:'',
            emailSup:window.supEmail,
            pastEvents:false
        }
    },
    components: {
        CalendarView
    },
    beforeMount:function(){
       this.loadEvents()
    },
    mounted() {
      this.currentMonth = moment(this.showDate).format('MMMM YYYY');
      this.nextMonth = moment(this.showDate).add(1, 'months').format('MMMM YYYY');
      this.previousMonth = moment(this.showDate).subtract(1, 'months').format('MMMM YYYY');
    },
    mixins: [CalendarMathMixin],
    methods: {
        filteredEvents(){
          var self=this;
          //console.log(self.classificationFilter);
          //console.log(self.creditTypeFilter);
          //console.log(self.events)
          if(self.classificationFilter===""&&self.creditTypeFilter==="")
          {
            return self.events
          }
          else
          {
            var aux= self.events;
            if(self.classificationFilter!=="") {
              aux = _.filter(aux, ['classification_id', parseInt(self.classificationFilter)])
            }
            if(self.creditTypeFilter!=="") {
              aux = _.filter(aux, ['credit_type_id', parseInt(self.creditTypeFilter)])
            }
            return aux
          }
        },
        searchCourse(){
          var self=this;
          if(self.nameFilter!="")
            window.location.href = window.location+'search/'+self.nameFilter
        },
        changeMonth(direction) {
          if (direction == 'next') {
            this.showDate = moment(this.showDate).add(1, 'months');
          } else if (direction == 'previous') {
            this.showDate = moment(this.showDate).subtract(1, 'months');
          }
          this.currentMonth = this.showDate.format('MMMM YYYY');
          this.nextMonth = moment(this.showDate).add(1, 'months').format('MMMM YYYY');
          this.previousMonth = moment(this.showDate).subtract(1, 'months').format('MMMM YYYY');
          this.showDate = this.showDate.toDate();
          //this.loadEvents();
        },
        loadEvents(){
            var self=this;
            var today=moment(self.showDate);
            var start=today.startOf('month').format('YYYY-MM-DD');
            var end=today.endOf('month').format('YYYY-MM-DD');
            var pastEvents=self.pastEvents
            axios.post('monthEvents',{ 'start_date':start,'end_date':end,'past_events':pastEvents })
            .then(function(res){
                //console.log(res);
                var data=_.map(res.data,function(item){
                    item.startDate=item.start_date;
                    item.endDate=item.end_date;
                    item.title=item.name;
                    return item;
                });
              //self.events=[];
                //console.log(data);
                self.events=data;

            }).catch(function(error){
                console.log(error);
            })
            //console.log("loading events")
        },
        onClickDay(d) {
            this.message = `You clicked: ${d.toLocaleDateString()}`
        },
        onClickEvent(e) {
            //item.url="/eventInfo?eventid="+e.originalEvent.id;
            //window.location()
            //var self=this;
            //console.log(e);
            //console.log("/eventInfo?eventid="+e.originalEvent.id);
            window.location.href ="/eventInfo?eventid="+e.originalEvent.id;
            //self.currentEvent=e.originalEvent
        },
        setShowDate(d) {
            this.message = `Changing calendar view to ${d.toLocaleDateString()}`
            this.showDate = d
        },
        moreInfoEvent(){
            window.location='eventInfo?eventid='+this.currentEvent.id;
        }
    },
    watch: {
        showDate:function(val)
        {
            this.loadEvents()
        }
    }
});

Vue.filter('formatTime', function(time) {
  if (time) {
    // Check correct time format and split into components
    time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1)
    { // If time format correct

      time = time.slice (1);  // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
      time[3] = '';

    }
    return time.join ('');
  }
});

Vue.filter('formatBool', function(value) {
  var temp="Yes";
  if(value==0)
    temp="No";
  return temp

});



